<template>
    <div>
        <Editor
                api-key="c3t31jo4bytbo0z12s6pqg0sftrdfw6tufd2gfonvhp1ncwd"
                :init="editorInit"
                v-model="content"
        >
        </Editor>
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue'
    export default {
        name: "editor",
        components:{Editor},
        data() {
            return {
                content:"",
                editorInit: {
                    height: 500,
                    min_height: 500,
                    menubar: true,
                    language:'zh_CN',
                    toolbar_mode:"sliding",
                    images_upload_url:this.$webConfig.serve.baseUrl+'/site/user/uploadImgOssEditor',
                    // plugins: [
                    //     'advlist autolink lists link image charmap print preview anchor',
                    //     'searchreplace visualblocks code fullscreen',
                    //     'insertdatetime media table paste code help wordcount powerpaste'
                    // ],
                    // toolbar:
                    //     'fullscreen table image media charmap emoticons hr pagebreak insertdatetime print preview code visualaid  | \
                    //     forecolor backcolor bold italic underline strikethrough link anchor | \
                    //     alignleft aligncenter alignright alignjustify outdent indent | \\\
                    //     bullist numlist blockquote subscript superscript removeformat bdmap indent2em lineheight formatpainter axupimgs\
                    //     |styleselect formatselect fontselect fontsizeselect'
                    plugins:"print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave autoresize",
                    toolbar:"code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \\\n" +
                        "    styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \\\n" +
                        "    table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen |  lineheight formatpainter",
                },
            }
        },
        props: {
            editorVal: {
                type: String,
                default: ""
            },
        },
        watch: {
            content:function (val) {
                this.$emit("getEditorVal",val)
            }
        },
        created() {
            this.content = this.editorVal;

        }
    }
</script>

<style scoped>

</style>
