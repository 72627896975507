<template>
    <div class="main">
        <el-page-header @back="goBack" :content="content">
        </el-page-header>
    </div>
</template>

<script>
    export default {
        name: "breadcrumb",
        methods: {
            goBack() {
                if (this.pathBack)
                {
                    this.$router.push(this.pathBack);
                }else{
                    window.history.back()
                }
            }
        },
        props:["content","pathBack"]
    }
</script>

<style scoped>
    .main{
        height: 30px;
        border-bottom: 1px solid #dcdfe6;
        padding-top: 0px;
        padding-left: 5px;
        margin-bottom: 20px;
    }
</style>
