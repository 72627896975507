<template>
    <div>
        <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :headers="headers"
                :data="others"
                :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
    </div>
</template>

<script>
    import Cookies from "js-cookie"
    export default {
        name:"index",
        data() {
            return {
                imageUrl:"",
                baseUrl:"",
                action:this.$webConfig.serve.baseUrl+"site/user/uploadImgOss",
                headers:{token:Cookies.get("token")},
                others:{flag:this.flag}
            };
        },
        props:["flag","outImgUrl"],
        methods: {
            handleAvatarSuccess(res, file) {
                if (res.code == 1000){
                    this.baseUrl = res.data.imgUrl;
                    this.imageUrl = this.filterImg(res.data.imgUrl) ;
                    this.$message.success("上传成功")
                }else {
                    this.$message.error(res.msg);
                    return;
                }
                this.$emit('uploadSuccess',{
                    flag:this.flag,
                    imgUrl:this.imageUrl,
                    baseUrl:this.baseUrl
                });
            },
            beforeAvatarUpload(file) {

                console.log(file.type);
                var allowType = [
                    "image/png",
                    "image/jpeg",
                    "image/gif",
                    "application/pdf",
                ];
                var deny = true;
                allowType.forEach((v)=>{
                    if (v == file.type) {
                        deny = false;
                    }else {
                        console.log(file.type)
                    }
                });
                if (deny){
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false;
                }

                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return  false;
                }

            },
            filterImg:function (img) {
                if (!img){
                    return "";
                }
                if (img.indexOf("http://") || img.indexOf("https://")){
                    return img;
                }

            }
        },
        created() {
            this.baseUrl = this.outImgUrl;
            this.imageUrl = this.filterImg(this.outImgUrl);
        }
    }
</script>

<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        height: 100%;
        display: block;
    }
</style>
